import React, { useState, useEffect } from 'react';
import { Button, Col, Fade, Form, Row, Spinner } from 'reactstrap';
import Plusguest from '../Mobile/icons/plusguest.svg'
import Ospite from './Checkin_ospite'
import getCookie from '../../functions/getCookie';
import parseQuery from '../../functions/parseQuery';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { getUser, getUserState } from '../../features/userSlice';
import { getHomeState } from '../../features/homepageSlice';
import { getCheckin, getCheckinState } from '../../features/checkinSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

function Checkin(props) {
    
    const history = useHistory();
    const params = useParams();
    const master = params.master; 
    const decode = params.decode; //parametri passati in base64 per autocopilazione
    const homeData = useSelector(getHomeState);

    let paramss = decode !== undefined ? atob(decode) : "";
    let query = parseQuery(paramss);

    //controllo che ci sia un utente per fillare i dati
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCheckin('/'+master+'/get-fastcheckin'));
        dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
    }, []);
    const userData = useSelector(getUserState);
    const checkinState = useSelector(getCheckinState);

    // const [codicepren, setCodicepren] = useState('')
    const [motivo, setMotivo] = useState('')
    // const [checkin, setCheckIn] = useState('')
    // const [checkout, setCheckOut] = useState('')
    const [numosp, setNumOsp] = useState('')

    const [checkinLoading, setcheckinLoading] = useState(false)

    const submitCheckin = async (e) => {
        e.preventDefault()
        var cisonoutenti = false;
        var data = new FormData();

        var nome = document.getElementsByClassName('nome')
        var cognome = document.getElementsByClassName('cognome')
        var email = document.getElementsByClassName('email')
        var telefono = document.getElementsByClassName('telefono')
        var privacy = document.getElementsByClassName('privacy')
        var marketing = document.getElementsByClassName('marketing')
        var foto1 = document.getElementsByClassName('foto1')
        var foto2 = document.getElementsByClassName('foto2')
        //var foto3 = document.getElementsByClassName('foto3')
        var fattura = document.getElementsByClassName('fattura')
        var ragionesociale = document.getElementsByClassName('ragionesociale')
        var partitaiva = document.getElementsByClassName('partitaiva')
        var indirizzo = document.getElementsByClassName('indirizzo')
        var cap = document.getElementsByClassName('cap')
        var comune = document.getElementsByClassName('comune')
        var codice = document.getElementsByClassName('codice')
        var pec = document.getElementsByClassName('pec')

        var checkin = document.getElementById('checkIn').value
        var checkout = document.getElementById('checkOut').value
        var codicepren = document.getElementById('codicePren').value

        //check indirizzi email
        var contaemail = 0;        
        for(var i = 0; i < email.length; i++){
            for(var j = 0; j < email.length; j++){
                if (email[j].value === email[i].value && email[i].value !== ""){
                    contaemail++;
                }
            }
        }

        if(contaemail > email.length){
            //le email sono uguali
            toast.error("Non è possibile utilizzare lo stesso indirizzo email per più utenti!")
        }else {

            for(i = 0; i < nome.length; i++){

                //se non sono compilati non aggiunge l'ospite
                if(numosp !== "" && codicepren !== "" && checkin !== "" && checkout !== "" && nome[i].value !== "" && cognome[i].value !== "" && email[i].value !== "" && privacy[i].value !== "false"){
                    cisonoutenti = true;
                    data.append('master[]', getCookie('master'));
                    data.append('codicepren[]', codicepren);
                    data.append('numosp[]', numosp);
                    data.append('motivo[]', motivo);
                    data.append('checkin[]', checkin);
                    data.append('checkout[]', checkout);
                    data.append('nome[]', nome[i].value);
                    data.append('cognome[]', cognome[i].value);
                    data.append('email[]', email[i].value);
                    data.append('marketing[]', marketing[i].value === "true" ? "1" : "0");

                    if(foto1[i].value !== "false") {
                        data.append('foto1[]', foto1[i].value);
                    }
                    if(foto2[i].value !== "false") {
                        data.append('foto2[]', foto2[i].value);
                    }
                    // if(foto3[i].value !== "false") {
                    //     data.append('foto3[]', foto3[i].value);
                    // }
                    data.append('telefono[]', telefono[i].value);
                    data.append('fattura[]', fattura[i].getAttribute("value") === "true" ? "1" : "0");
                    data.append('ragsoc[]', ragionesociale[i].value);
                    data.append('piva[]', partitaiva[i].value);
                    data.append('indirizzo[]', indirizzo[i].value);
                    data.append('cap[]', cap[i].value);
                    data.append('comune[]', comune[i].value);
                    data.append('coddest[]', codice[i].value);
                    data.append('emailpec[]', pec[i].value);
                }

            }

        }
        
        if(cisonoutenti){

            setcheckinLoading(true)

            let response = await fetch('/checkin_act.php', {
                method: "POST",
                headers: {
                    mode: 'cors'
                },
                body: data
            })
            
            console.log(await response.json()['esito'])
            
            setcheckinLoading(false)

            history.push("/thankyoufc")
            
        }else {
            toast.error("Completare tutti i campi!")
        }
        
    }

    //gestione ospiti aggiunti
    const [ospiti, setOspiti] = useState(1)

    const addOspite = () => {
        setOspiti(ospiti + 1)
    }

    const removeOspite = () => {
        setOspiti(ospiti === 1 ? 1 : ospiti - 1)
    }

    const items = []

    for (var i = 1; i < ospiti; i++) {
        items.push(<Ospite close={() => removeOspite} key={i} number={i+1} master={master} />)
    }
    //fine gestione ospiti aggiunti

    return (
        <>
            {!checkinState.loading &&
                <Fade>

                    <ToastContainer className="text-wrap" />
                    <div className="bg-light">
                    <Form onSubmit={submitCheckin} noValidate>
                        <div className="pt-3 pl-5 container-fluid">
                        <span className="pt-3 d-block" style={{ fontSize: "26px", fontWeight:"bold" }}>Fast Check-In </span>
                        <div className="row">
                            <div className="col-8" style={{height: "78vh", overflow: "scroll"}}>
                                <Ospite key="1" number={1} data={userData} master={master}/>
                                {/*Inserire qua il nuovo utente*/}
                                {items}
                                <Row className="mt-3">
                                    <Col>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col onClick={addOspite} >
                                        <img src={Plusguest} alt=""/>
                                        <span className="pl-3">{homeData.testo146}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr />
                                    </Col>
                                </Row>
                            </div>
                            <div className="col-4 pr-5">
                                {query.codicePren ?
                                    <input className="input-service my-2" id='codicePren' placeholder={checkinState.data.testo25} style={{ borderColor: "#f5f5f5" }} defaultValue={query.codicePren} required disabled/>
                                :
                                    <input className="input-service my-2" id='codicePren' placeholder={checkinState.data.testo25} style={{ borderColor: "#f5f5f5" }} required />
                                }
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        <span>Check-in</span>
                                    </Col>
                                    <Col>
                                        {query.checkIn ?
                                            <input type="date" id='checkIn' className="input-service float-right" placeholder="30 Oct" defaultValue={query.checkIn} required disabled/>
                                        :
                                            <input type="date" id='checkIn' className="input-service float-right" placeholder="30 Oct" required />
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="d-flex align-items-center">
                                        <span>Check-out</span>
                                    </Col>
                                    <Col>
                                        {query.checkOut ?
                                            <input type="date" id='checkOut' className="input-service float-right" placeholder="2 Nov" defaultValue={query.checkOut} required disabled/>
                                        :
                                            <input type="date" id='checkOut' className="input-service float-right" placeholder="2 Nov" required />
                                        }
                                    </Col>
                                </Row>
                                <input className="input-service my-2" placeholder={checkinState.data.testo26} style={{ borderColor: "#f5f5f5" }} onChange={(e) => setNumOsp(e.target.value)} required />
                                <Row className="mt-3">
                                    <Col>
                                        <hr />
                                    </Col>
                                    <Col>
                                        <span className="text-nowrap">{homeData.testo126}</span>
                                    </Col>
                                    <Col>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col>
                                        <input type="radio" name="stay" value="vacation" id="vacation" className="w-100" onClick={() => setMotivo('V')} />
                                        <label htmlFor="vacation">{checkinState.data.testo11}</label>
                                    </Col>
                                    <Col>
                                        <input type="radio" name="stay" value="work" id="work" className="w-100" onClick={() => setMotivo('L')} />
                                        <label htmlFor="work">{checkinState.data.testo12}</label>
                                    </Col>
                                    <Col>
                                        <input type="radio" name="stay" value="other" id="other" className="w-100" onClick={() => setMotivo('A')} />
                                        <label htmlFor="other">{checkinState.data.testo16}</label>
                                    </Col>
                                </Row>
                                {!checkinLoading
                                ?
                                    <Button type="submit" className="btn-first font-weight-bold w-100 mt-4">CHECK IN</Button>
                                :
                                    <Button type="submit" className="btn-first font-weight-bold w-100 mt-4">
                                        <Spinner style={{ width: '1rem', height: '1rem' }} children={false} />                                
                                    </Button>
                                }
                            </div>
                        </div>
                        </div>
                        </Form>
                    </div>
                </Fade>
            }
        </>
    )
}

export default Checkin