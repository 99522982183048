import { Col, Fade, Row } from 'reactstrap'
import Servizi from '../Mobile/Servizi';
import Placelist from './Placelist';
import Categorielist from '../Mobile/Categorielist';
import SearchBar from '../Mobile/SearchBar';
import { getHomeState, getHomeStatus } from '../../features/homepageSlice'
import { Redirect } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserState } from '../../features/userSlice'
import { getBootStatus } from "../../features/bootstrapSlice"
import getCookie from '../../functions/getCookie'
import React, { useEffect } from 'react';
import purify from "dompurify";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Home() {
    let query = useQuery();
    if(query.get("master")){
        //setCookie('master', query.get("master"), 365)
    }

    //aggiorno l'utente in caso di refresh
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
    }, []);

    const homeData = useSelector(getHomeState);
    const statusHome = useSelector(getHomeStatus);
    const userData = useSelector(getUserState);
    const bootState = useSelector(getBootStatus);

    const gitav = ["1781", "1782", "1780", "1789", "1778", "1783", "1784", "1785", "1786", "1787", "1788", "1790", "1791", "1793", "1792"];

    if(bootState.data.master === "1325" || bootState.data.master === "1539" || bootState.data.master === "1535" || bootState.data.master === "1537" || bootState.data.master === "1540" || bootState.data.master === "1550"){
        var titoloStyle = {fontFamily: "'Lato', sans-serif"}
    }else if(bootState.data.master === "1920") {
        var titoloStyle = {fontFamily: "'Nanum Gothic', sans-serif"}
    }else if(gitav.includes(bootState.data.master)) {
        var titoloStyle = {fontFamily: "'Montserrat', sans-serif"}
    }else if(bootState.data.posto_master.fontwelcome !== "") {
        if(document.getElementById('welcometext'))
        document.getElementById('welcometext').style.cssText += bootState.data.posto_master.fontwelcome;
    }else {
        var titoloStyle = {fontFamily: "'Pacifico', cursive"}
    }

    if(query.get('unsubscribe')){
        return(
            <Redirect to="/unsubscribe" result={query.get('unsubscribe')}/>
        );
    }else if(query.get('master') && query.get('page') === 'modulorep'){
        return(
            <Redirect to="/moduloreputazione" master={query.get('master')}/>
        );
    }else if(query.get('id') && query.get('page') === 'place'){
        return(
            <Redirect to={"/place/"+query.get('id')} />
        );
    }else if(query.get('id') && query.get('page') === 'itinerary'){
        return(
            <Redirect to={"/itinerary/"+query.get('id')} />
        );
    }else if(query.get('id') && query.get('page') === 'service'){
        return(
            <Redirect to={"/service/"+query.get('id')} />
        );
    }else if(query.get('decode')){
        return(
            <Redirect to="/questionario/:decode" />
        );
    }else{
        return(
            <Fade>
                <Row className="my-3 mx-auto">
                    <Col style={titoloStyle} id="welcometext">
                        { document.cookie.match('connesso') && userData.data.hasOwnProperty('utente') && !statusHome.loading
                        ?
                            <h5>{homeData.testo125.replace('[ospite]',userData.data.utente.nome)}</h5>
                        :
                            <h5>{homeData.testo93}</h5>
                        }
                    </Col>
                </Row>

                {bootState.data.posto_master.meteo == "1" &&
                    <div className="card card-body bg-transparent" dangerouslySetInnerHTML={{ __html: purify.sanitize(bootState.data.posto_master.meteo_html, { USE_PROFILES: { html: true }, ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }) }}>

                    </div>
                }

                <Col className="card border-none">
                    <Servizi frompath="/"/>
                </Col>

                <SearchBar />

                {homeData.sezioni && homeData.sezioni.length > 0 && homeData.sezioni.map((sezione, index) =>
                    sezione.items.length > 0 &&
                    <Placelist key={index} item={homeData.posti} sezione={sezione} />
                )}
            </Fade>

        );
    }
}

export default Home;